import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  backgroundImage?: ImageProps
  claim?: string
  className?: string
  cta?: ButtonProps
  description?: string
  mainImage?: ImageProps
  title?: string
}

export const Item = memo(function Item({
  backgroundImage,
  claim,
  className,
  cta,
  description,
  mainImage,
  title,
}: Props) {
  return (
    <Container className={className}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Background row wrap stretch>
          <LeftSide>
            {backgroundImage ? (
              <LazyLoadImage className="item-bg" {...backgroundImage} />
            ) : null}
          </LeftSide>
          <RightSide dial={9}>
            <Media greaterThanOrEqual="desktopSmall">
              {claim ? (
                <Claim
                  className="item-claim"
                  dangerouslySetInnerHTML={{ __html: claim }}
                />
              ) : null}
            </Media>
          </RightSide>
        </Background>
        <Wrapper row wrap>
          <Text className="item-text">
            {title ? <Title>{title}</Title> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            <Media greaterThanOrEqual="desktopSmall">
              {cta ? <CTA {...cta} variant="W" /> : null}
            </Media>
          </Text>
          {mainImage ? (
            <MainImage className="item-image">
              <LazyLoadImage {...mainImage} />
            </MainImage>
          ) : null}
          <Media lessThan="desktopSmall">
            {claim ? (
              <Claim
                className="item-claim"
                dangerouslySetInnerHTML={{ __html: claim }}
              />
            ) : null}
          </Media>
          <Media lessThan="desktopSmall">
            {cta ? <CTA {...cta} variant="W" /> : null}
          </Media>
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  &.active {
    .item-bg {
      transform: scale(1);
    }
    .item-claim {
      opacity: 1;
      transform: rotate(-180deg);
      transition-delay: 0.7s;
    }
    .item-text {
      opacity: 1;
      transform: none;
    }
    .item-image {
      opacity: 1;
      transform: none;
      transition-delay: 1s;
    }
  }
`

const Background = styled(FlexBox)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const LeftSide = styled.div`
  width: 72%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.1);
    transition: 0.6s ease-in-out;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const RightSide = styled(FlexBox)`
  width: 28%;
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  padding: 0 4.3vw 12.9vh 0;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0;
  }
`

const Claim = styled.div`
  max-height: 600px;
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4rem;
  overflow: hidden;
  opacity: 0;
  text-transform: uppercase;
  transform: translateX(-5.625rem) rotate(-180deg);
  transition: 0.6s ease-in-out;
  word-break: break-word;
  writing-mode: vertical-rl;

  @media (max-width: 1023px) {
    max-height: none;
    font-size: 3.125rem;
    line-height: 2.875rem;
    margin-top: 2.5rem;
    transform: none !important;
    writing-mode: initial;
    br {
      content: ' ';
      display: inline;
      padding: 0 0.1875rem;
    }
  }
`

const Wrapper = styled(FlexBox)`
  padding-top: 19.6vh;
  position: relative;
  z-index: 2;

  @media (max-width: 1023px) {
    display: block;
    padding: 5rem 6.039vw 2.5rem;
    text-align: center;
  }
`

const Text = styled.div`
  max-width: 33.3vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin: 2.25rem 9.167vw 0 0;
  padding-left: 5.833vw;
  opacity: 0;
  transform: translateY(3.75rem);
  transition: 0.6s ease-in-out;

  @media (max-width: 1023px) {
    max-width: none;
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: center;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 1.25rem;
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
`

const MainImage = styled.div`
  width: 35.8vw;
  height: 45.2vh;
  border: 0.625rem solid ${({ theme }) => theme.colors.variants.neutralLight3};
  position: relative;
  opacity: 0;
  transform: translateX(5.625rem);
  transition: 0.6s ease-in-out;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: auto;
    margin-top: 1.875rem;
    padding-bottom: 70%;
  }
`
