import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  icon?: string
  label?: string
}

export const Item = memo(function Item({ icon, label }: Props) {
  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: '3.75rem',
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      {icon ? <Icon alt={label} src={icon} width="30" height="30" /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 50%;
  margin-top: 1.875rem;
  padding-right: 5.833vw;

  @media (max-width: 1339px) {
    padding-right: 2.5vw;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 1.625rem;
    padding-right: 0;
  }
`

const Icon = styled.img`
  width: auto;
  height: 1.875rem;
  margin-right: 1rem;
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-transform: uppercase;
`
