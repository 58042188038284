import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as RoomProps, Room } from './Room'

export interface Props {
  cta?: ButtonProps
  label?: string
  rooms: RoomProps[]
  title?: string
}

export const RoomsPreview = memo(function RoomsPreview({
  cta,
  label,
  rooms,
  title,
}: Props) {
  if (!rooms) {
    return null
  }

  return (
    <Container row wrap space="between" tag="section">
      <Text>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
      </Text>
      {rooms.slice(0, 3).map((item, index) => (
        <Room
          key={index}
          {...item}
          index={index}
          URL={cta ? cta.URL : undefined}
        />
      ))}
      {cta ? (
        <FadeInUp>
          <CTA {...cta} variant="W" />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  padding: 12.5rem 0;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    padding: 5rem 6.039vw 3.75rem;

    > div {
      &:last-of-type {
        width: 100%;
        text-align: center;
      }
    }
  }
`

const Text = styled.div`
  width: 50%;
  padding: 0 10.417vw 0 9.375vw;

  @media (max-width: 1339px) {
    padding-right: 5vw;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0;
  }
`

const Label = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  margin-bottom: 0.375rem;
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 700;
  line-height: 4rem;

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
  }
`

const CTA = styled(Button)`
  margin-left: 16.806vw;
  transform: translateY(-3.75rem);

  @media (max-width: 1023px) {
    margin: 3.75rem auto 0;
    transform: none;
  }
`
