import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { ModalClose } from 'app/components/Common/Header/ModalClose'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import useLockedBody from 'app/utils/useLockedBody'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo, useState } from 'react'

export interface Props {
  cta?: ButtonProps
  description?: string
  label?: string
  languageCode: string
  image?: ImageProps
  title?: string
  URL?: string
}

export const Sticker = memo(function Sticker({
  cta,
  description,
  label,
  languageCode,
  image,
  title,
  URL,
}: Props) {
  const [firstLoad, setFirstLoad] = useState(false)
  const [modalStickerStatus, setModalStickerStatus] = useState(false)
  const [_locked, setLocked] = useLockedBody()

  const buttonDOM = (
    <>
      <LabelContent>{label}</LabelContent>
      <ChevronRight />
    </>
  )
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  return (
    <Container>
      {label ? (
        URL ? (
          externalURL ? (
            <ExtLink href={URL} rel="noopener" target="_blank">
              {buttonDOM}
            </ExtLink>
          ) : (
            <Anchor to={URL}>{buttonDOM}</Anchor>
          )
        ) : (
          <Label
            onClick={() => {
              if (!modalStickerStatus) {
                setFirstLoad(true)
                setModalStickerStatus(true)
                setLocked(true)
              } else {
                setLocked(false)
                setModalStickerStatus(false)
              }
            }}
          >
            {buttonDOM}
          </Label>
        )
      ) : null}

      {firstLoad ? (
        <Modal
          className={`${modalStickerStatus ? 'open' : ''}${
            firstLoad ? ' first' : ''
          }`}
          dial={5}
          onClick={() => {
            setLocked(false)
            setModalStickerStatus(false)
          }}
        >
          <Wrapper row stretch onClick={(e) => e.stopPropagation()}>
            {image ? (
              <ImageWrap>
                <Image {...image} />
              </ImageWrap>
            ) : null}
            <TextWrap className={!image ? 'full-width' : undefined}>
              <ModalClose
                languageCode={languageCode}
                onClick={() => {
                  setLocked(false)
                  setModalStickerStatus(false)
                }}
                variant="SR"
              />
              {title ? <Title>{title}</Title> : null}
              {description ? (
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              ) : null}
              {cta ? <CTA {...cta} /> : null}
            </TextWrap>
          </Wrapper>
        </Modal>
      ) : null}
    </Container>
  )
})

const Container = styled.aside`
  padding: 0 5.833vw;
`

const Style = css`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 13.75rem;
  border: 0.625rem solid ${rgba(theme.colors.variants.neutralLight3, 0.2)};
  color: ${theme.colors.variants.neutralLight3};
  cursor: pointer;
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0469rem;
  line-height: 1.125rem;
  padding: 2.25rem 2.375rem;
  position: absolute;
  top: 8.5rem;
  left: 5.833vw;
  z-index: 300;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  &:hover {
    background: ${theme.colors.variants.neutralDark2};
    border-color: transparent;
  }

  svg {
    width: 1.375rem;
    height: auto;
    fill: none;
    margin-left: 2.9375rem;
    stroke: ${theme.colors.variants.neutralLight3};
    stroke-linecap: square;
    stroke-linejoin: round;
    stroke-width: 4;
  }

  @media (max-width: 1199px) {
    top: 5.625rem;
    left: 1.5625rem;
  }

  @media (max-height: 650px) {
    max-width: none;
    justify-content: center;
    padding: 24px 6.039vw;
    top: 3.75rem;
    right: 0;
    left: 0;
    svg {
      display: none;
    }
  }
`

const Label = styled.div`
  ${Style}
`

const Anchor = styled(Link)`
  ${Style}
`

const ExtLink = styled.a`
  ${Style}
`

const LabelContent = styled.span`
  width: calc(100% - 4.3125rem);
`

const Modal = styled(FlexBox)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;

    > div {
      transform: translateY(0);
    }
  }
  &.first {
    animation: opacity 0.15s;
    > div {
      animation: fadeWrapper 0.6s;
    }
    img {
      animation: opacity 1s;
    }
  }

  @keyframes fadeWrapper {
    0% {
      transform: translateY(5.625rem);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  max-width: 73.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  cursor: default;
  margin: auto;
  transform: translateY(5.625rem);
  transition: 0.6s;

  @media (max-width: 767px) {
    display: block;
    height: 100vh;
    max-height: -webkit-fill-available;
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;
  }
`

const ImageWrap = styled.div`
  width: 50%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.05);
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const TextWrap = styled.div`
  width: 50%;
  padding: 4.875rem 5.833vw;
  position: relative;
  &.full-width {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 5rem 1.9375rem 3rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3.25rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    margin-top: 1.5rem;
  }
`

const CTA = styled(Button)`
  margin-top: 3.125rem;

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
  }
`
