import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Item, Props as ItemProps } from './Item'

const AUTOPLAY_TIMER = 5000

export interface Props {
  items: ItemProps[]
}

export const PocketConcierge = memo(function PocketConcierge({ items }: Props) {
  if (!items) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacities, setOpacities] = useState([0, 0])
  const [pause, setPause] = useState(false)
  const timer = useRef<any>()

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slides: items.length,
    loop: true,
    move(s) {
      const opacities = s.details().positions.map((slide) => slide.portion)
      setOpacities(opacities)
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })

  useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, pause, slider, sliderRef])

  return (
    <Container>
      <Slider ref={sliderRef}>
        {items.map((item, index) => (
          <Slide
            key={index}
            className={currentSlide === index ? 'active' : undefined}
            style={{
              opacity: opacities[index],
            }}
          >
            <Item
              className={currentSlide === index ? 'active' : undefined}
              {...item}
            />
          </Slide>
        ))}
      </Slider>
      {items.length > 1 ? (
        <Dots row wrap>
          {items.map((item, index) => (
            <Dot
              key={index}
              className={currentSlide === index ? 'active' : undefined}
              onClick={() => {
                setPause(true)
                slider.moveToSlideRelative(index)
              }}
            />
          ))}
        </Dots>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  position: relative;
`

const Slider = styled.div`
  height: 86.2vh;
  background: ${({ theme }) => theme.colors.variants.neutralDark1};

  @media (max-width: 1023px) {
    height: calc(100vh + 5rem);
    min-height: 58.75rem;
  }
`

const Slide = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s;
`

const Dots = styled(FlexBox)`
  position: absolute;
  bottom: 11.5vh;
  left: 5.833vw;
  z-index: 3;

  @media (max-width: 1023px) {
    justify-content: center;
    bottom: 5rem;
    right: 0;
    left: 0;
  }
`

const Dot = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border: 4px solid ${({ theme }) => theme.colors.variants.neutralLight3};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.2;
  margin-right: 1.875rem;
  transition: 0.3s ease-in-out;
  &:last-of-type {
    margin-right: 0;
  }
  &.active {
    opacity: 1;
    pointer-events: none;
  }

  @media (max-width: 1023px) {
    margin: 0 0.9375rem;
    &:last-of-type {
      margin-right: 0.9375rem;
    }
  }
`
